<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff && tariff.status" class="vps-detail__inner">
        <div class="vps-detail__content">
          <layout-stack-title
            v-if="specsObj"
            :title="tariff.name"
            :specs="specsObj"
            :address="tariff.id"
            :status="tariff.status"
            :task="tariff.task"
            :description="tariff.description"
            type="server"
            :created="tariff.created"
            :deletable="isDeleteDisk"
            :state="tariff.serverState"
            class="vps-detail__head"
            ><template #btn>
              <v-popover
                v-if="isMobile && serverid"
                placement="bottom"
                popover-class="vps-detail__popover"
              >
                <sidebar-button /><template #popover>
                  <server-context-menu
                    :tariff="tariff"
                    :is-loading="isCurrentLoading"
                    class="vps-detail__context-menu"
                /></template> </v-popover
            ></template>
          </layout-stack-title>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="tariff" :loading="loading" :tariff="tariff"></router-view>
          </transition>
        </div>

        <main-card v-if="!isMobile" class="vps-detail__aside">
          <server-context-menu
            v-if="serverid"
            :tariff="tariff"
            :is-loading="isCurrentLoading || isLoading"
          />
          <base-loader v-else />
        </main-card>
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import MainCard from '@/components/MainCard/MainCard';
import ServerContextMenu from '../pages/Main/components/ServerContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
export default {
  name: 'Server',
  components: {
    LayoutStackTitle,
    tabs,
    SidebarButton,
    MainCard,
    ServerContextMenu,
    BaseAlert,
  },
  mixins: [storeMixin, providerChange],
  props: {
    serverid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCurrentLoading: false,
      localDisk: false,
      stats: {},
      isServerError: false,
      isMobile: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.moduleStack.serverRequest;
    },
    navRaw() {
      return [
        {
          title: this.$t('nav.config'),
          to: { name: 'ServerView' },
          access: true,
        },
        {
          title: this.$t('nav.disk'),
          to: { name: 'ServerDisk' },
          access: true,
        },
        {
          title: this.$t('nav.port'),
          to: { name: 'ServerPort' },
          access: true,
        },
        {
          title: this.$t('nav.vnc'),
          to: { name: 'ServerVnc' },
          access: this.tariff.status.code !== 2,
        },
        {
          title: this.$t('nav.stat'),
          to: { name: 'ServerStat' },
          access: true,
        },
        {
          title: this.$t('nav.log'),
          to: { name: 'ServerLog' },
          access: this.tariff.status.code !== 2,
        },
      ];
    },
    volumeId() {
      return this.tariff['os-extended-volumes:volumes_attached'].length > 0
        ? this.tariff['os-extended-volumes:volumes_attached'][0].id
        : null;
    },
    tariff() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.serverid);
    },
    metadate() {
      return this.volumeId &&
        this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId)
        ? this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId).metadata ||
            this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId)
              .volume_image_metadata
        : '-';
    },
    osName() {
      return this.tariff.image &&
        !this.loading &&
        this.$store.state.moduleStack.images &&
        this.tariff.image.id &&
        this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id)
        ? this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id).name
        : this.volumeId && !this.localDisk
        ? this.metadate.image_name
        : ' ';
    },
    currentServer() {
      return this.$store.state.moduleStack.currentServer;
    },
    specsObj() {
      if (this.currentServer.os && this.currentServer.os !== ' ') {
        let list = [];
        if (this.currentServer.type === 'local') list.push('Локальный');
        // if (this.currentServer.cpu) list.push(this.currentServer.cpu.toString());
        // if (this.currentServer.ram) list.push(this.currentServer.ram.toString());
        return {
          // head: this.currentServer.os,
          list,
        };
      } else {
        const specs = this.tariff.flavor;

        const os =
          this.tariff.image &&
          !this.isLoading &&
          !this.loading &&
          this.$store.state.moduleStack.images &&
          this.tariff.image.id &&
          this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id)
            ? this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id).name
            : this.volumeId
            ? this.osName
            : ' ';
        let list = [];
        if (specs.vcpus) list.push(specs.vcpus.toString());
        if (specs.ram) list.push(specs.ram.toString());
        if (this.localDisk) list.push('Локальный');

        this.$store.dispatch('moduleStack/setCurrentServer', {
          os: os,
          type: this.localDisk ? 'local' : null,
          ram: specs.ram.toString(),
          cpu: specs.vcpus.toString(),
        });
        return {
          head: os,
          list,
        };
      }
    },
    nav() {
      return this.navRaw.filter(i => i.access);
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    isDeleteDisk() {
      return (
        this.tariff &&
        this.tariff['os-extended-volumes:volumes_attached'] &&
        this.tariff['os-extended-volumes:volumes_attached'][0] &&
        this.tariff['os-extended-volumes:volumes_attached'][0].delete_on_termination
      );
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
    localDisk(event) {
      if (event) {
        this.$store.dispatch('moduleStack/setCurrentServer', {
          type: this.localDisk ? 'local' : null,
        });
      }
    },
    'tariff.status': function (event) {
      if (event && event.code === 2) this.checkStatus(_, this.serverid);
    },
  },
  mounted() {
    if (this.tariff && this.tariff.status === 2) {
      this.checkStatus(_, this.serverid);

      this.fetchServer();
    } else {
      this.fetchServer();
      this.getLocalDisk();
    }
    this.$nextTick(() => this.setIsMobile());
  },
  beforeRouteLeave(_to, _from, next) {
    this.status = {};
    next();
  },
  methods: {
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    async checkStatus(callback, id) {
      this.timerCnt = 2;
      this.timerId = setTimeout(() => {
        this.$store.dispatch('moduleStack/getServerInfo', id).then(async data => {
          if (
            data['os-extended-volumes:volumes_attached'].length === 0 &&
            data.status !== 'ACTIVE'
          ) {
            this.res = data;
            await this.checkStatus(callback, id);
          } else if (data.status === 'VERIFY_RESIZE') {
            this.res = 'data';
            this.sendServerAction(JSON.stringify({ confirmResize: null }));
            this.timerCnt = 3;
            await this.checkStatus(callback, id);
          } else {
            this.timerCnt = 1;
            const id =
              data['os-extended-volumes:volumes_attached'] &&
              data['os-extended-volumes:volumes_attached'].length > 0
                ? data['os-extended-volumes:volumes_attached'][0].id
                : null;

            if (id) {
              const payload = { disk: id };
              this.$store
                .dispatch('moduleStack/checkDiskStatus', payload)
                .then(async data => {
                  return data;
                })
                .then(data => {
                  const params = {
                    id: id,
                    params: {
                      volume: { name: `${this.tariff.name}-disk` },
                    },
                    type: 'name',
                  };
                  if (!data.name) {
                    this.$store
                      .dispatch('moduleStack/fetchOpenStackRequest', 'volumes')
                      .then(() => {
                        this.$store.dispatch('moduleStack/renameDisk', params);
                      });
                  }
                });
            } else this.getLocalDisk();

            clearTimeout(this.timerId);
            this.res = data;
            return 'success';
          }
        });
      }, 5000 * this.timerCnt);
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    getLocalDisk() {
      this.$store
        .dispatch('moduleStack/isLocalDisk', { id: this.$attrs.id, uuid: this.serverid })
        .then(async data => {
          if (data && data.result === 'ok') {
            this.localDisk = data.existslocaldisk;
          }
        })
        .catch(e => console.log(e));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "nav": {
        "config": "Конфигурация",
        "disk": "Сетевые диски",
        "port": "Порты",
        "stat": "Статистика",
        "vnc": "Консоль VNC",
        "log": "Лог"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  margin-top: 0.5rem

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
